<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Multiselect from "vue-multiselect";
import btnComponent from "@/components/btnComponent";
export default {
  page: {
    title: "คลังอะไหล่",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect ,btnComponent },

  data() {
    return {
      loading: undefined,

      title: "คลังอะไหล่",
      items: [
        {
          text: "อะไหล่",
          href: "/",
        },
        {
          text: "คลังอะไหล่",
          active: true,
        },
      ],
      filter: {
        partCode: "",
        partNameTh: "",
      },
      totalPage:"",
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowParts: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterOn: [],
      sortBy: "partId",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,

        //? search
      branchId:"",
      optionMasterST:[],
      masterST:"",
      namePartSearch:"",
      idPartSearch:"",

      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "partCode",
          label: "รหัสอะไหล่",
          sortable: true,
        },
        {
          key: "partNameTh",
          label: "ชื่ออะไหล่",
          sortable: true,
        },
        {
          key: "cost",
          label: "ราคาทุน",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคาขาย",
          sortable: true,
        },
        {
          key: "retailPrice",
          label: "ราคาหน้าร้าน",
          sortable: true,
        },
        {
            key:"amount",
            label:"คงเหลือ",
            sortable: true,
        },
        {
            key:"amountInProcess",
            label:"ระหว่างทำ",
            sortable: true,
        },
        {
            key:"updatedAt",
            label:"วันที่ปรับปรุงล่าสุด",
            sortable: true,
        },

        // "action",
      ],
      branchIdUser:"",
      localDataBranch:[],
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowParts.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.rowParts = this.items.length;
  },

  created() {
    this.getLocalData();
    this.getMasterPartST();
    // this.getPartST();
    this.startIndex = 1;
    this.endIndex = this.perPage;
    this.paginatedData = this.rowParts.slice(this.startIndex, this.endIndex);
  },
  methods: {
    getReport() {
      this.loading = true;
      useNetw
        .get("api/report/part-stock", {
          params: {
            stockId: this.masterST.stockId,
          },
        }) // ?เอามาจากไหน
        .then((data) => {
          // this.linkPtintFlowslot = data.data.url;
          window.location = (data.data.url);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูล"),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
      getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchIdUser = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.branchId = user.userAccessBranch[0];
      this.localDataBranch = localDataBranch;
      // // console.log(this.localDataBranch);
    },
    fetchCarslot() {
      this.rowSlot.vehicle(function(value) {
        this.listSlot.push(value);
      });

      // // console.log("vehicle", this.listSlot);
    },
    handleSearch() {
      this.getBranchPartST();
    },
    onFiltered(filteredItems) {
      this.rowParts = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowParts) {
      this.selected = rowParts;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    getMasterPartST: function () {
        this.rowParts = [];
        this.optionMasterST = [];
        this.masterST = [];
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/stock-part", {
          params: {
            branchId: this.branchId.branchId,  
          },
        })
        .then((response) => {
          // console.log("Master Stock ==> " , response.data.data);
          this.optionMasterST = response.data.data;
          // // console.log(response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getBranchPartST();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getBranchPartST();
    },
    getBranchPartST: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/stock-part", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchId.branchId,
            stockId: this.masterST.stockId,
            partCode: this.idPartSearch,
            partNameTh: this.namePartSearch,
          },
        })
        .then((response) => {
        // console.log('branchPart ===>', response.data);
          this.rowParts = response.data.data;
        //   // console.log(this.rowParts.length);
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord /  this.perPage);
          // // console.log(response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="col-sm-12 col-md-12">
              <!-- <router-link
                :to="{ name: 'add-masterPart' }"
                class="btn btn-primary float-end"
              >
                <i class="mdi mdi-plus me-1"></i>เพิ่มข้อมูลอะไหล่
              </router-link> -->
            </div>
            <h4>คลังอะไหล่</h4><br>

            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <div class="row">
                  <div class="col-md-6">
                        <div class="mb-3 position-relative">
                            <code> * </code>
                            <label for="branchId">สาขา :</label><br />
                            <multiselect
                            v-model="branchId"
                            :options="localData"
                            @input="getMasterPartST()"
                            label="nameTh"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3 position-relative">
                            <code> * </code>
                            <label for="branchId">คลัง :</label><br />
                            <multiselect
                            v-model="masterST"
                            :options="optionMasterST"
                            label="nameTh"
                            @input="getBranchPartST"
                            >
                            </multiselect>
                        </div>
                    </div>
              </div>
              <div class="row">
                  <div class="col-md-4">
                        <div class="mb-3 position-relative">
                            
                            <label for="branchId">รหัสอะไหล่ :</label><br />
                            <input 
                            type="text" 
                            class="form-control"
                            v-model="idPartSearch"
                            >
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3 position-relative">
                            <label for="branchId">ชื่ออะไหล่ :</label><br />
                            <input type="text" class="form-control"
                            v-model="namePartSearch"
                            >
                        </div>
                    </div>
                    <div class="col-md-4 text-end">
                        <label for="branchId"><code>หากต้องการพิมพ์รายงาน กรุณาเลือกสาขา และคลังสินค้า</code></label><br />
                        <btnComponent @click="handleSearch" changeStyle="search" > </btnComponent> &nbsp;
                        <button :disabled="masterST == ''" @click="getReport()" class="btn btn-primary"><i class="uil uil-print"></i> พิมพ์</button>
                    </div>
              </div>

              <hr>
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          แสดงผล&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp;รายการ
                         </label>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <!-- Table -->
              <div class="table-responsive mb-0" v-if="rowParts.length > 0">
                <b-table
                  v-if="rowParts && rowParts.length > 0 && !isLoading"
                  id="table-transition-userList"
                  :key="rowParts.id"
                  :items="rowParts"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :select-mode="selectMode"
                  ref="selectableTable"
                  selectable
                  @row-selected="onRowSelected"
                >
                  <template #cell(index)="rowParts" v-if="this.currentPage > 1">
                    {{ rowParts.index + 1 + (currentPage * perPage - perPage) }}
                  </template>
                  <template #cell(index)="rowParts" v-else>
                    {{ rowParts.index + 1 }}
                  </template>
                  <!-- edit table -->
                  <template v-slot:cell()="{ value, item, field: { key } }">
                    <template v-if="!item.isEditing">{{ value }}</template>
                    <b-form-input v-else v-model="item[key]" />
                  </template>
                  <!-- end edit table -->
                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>

                  <!-- edit table -->
                  <template v-slot:cell(action)="rowParts">
                    <router-link
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      :to="{
                        name: 'update-masterPart',
                        params: { partId: rowParts.item.partId },
                      }"
                    >
                      <i class="uil uil-pen font-size-18"></i>
                    </router-link>

                    <!-- <a
                      class="px-2 text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      @click="alert(rowParts.item.partId)"
                    >
                      <i class="uil uil-trash-alt font-size-18"></i>
                    </a> -->
                  </template>
                  <!-- End edit table -->
                </b-table>

                <!-- <b-button v-model="selected" size="sm" @click="selectAllRows"
                  >Select all</b-button
                >
                &nbsp;

                <b-button size="sm" @click="clearSelected"
                  >Clear selected</b-button
                >

                <p>
                  Selected Rows:<br />
                  {{ selected }}
                </p> -->
              </div>
              <div class="div" v-else>
                <div class="text-center"><b>ไม่พบข้อมูล</b></div>
              </div>
              <br />
              <div class="row">
                <div class="col" v-show="rowParts.length > 0">
                  <label class="d-inline-flex align-items-center" >
                    หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                    {{ totalRecord }} รายการ
                   :</label>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecord"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>